import React from "react"
import { Helmet } from "react-helmet"

import Layout from "@components/layout"
import ManageSubscription from "@components/ManageSubscription"

export default function Beta() {
  
  return (
    <main>
      <Helmet>
        <html lang="en" />
        <title>FasTrack: Manage Subscription</title>
        <meta name="description" content="FasTrack beta signup" />
      </Helmet>
      <Layout nobg>

        <div className="container py-8">

          <ManageSubscription />

        </div>

      </Layout>
      
    </main>
  )
}
