import React, { Component } from "react"
import Spinner from "./Spinner";

const lookupUrl = `/.netlify/functions/commerce/customer-portal`;

const inputClasses = `mb-8 p-2 flex-grow text-blue-900 bg-gray-100 border-b-2 border-gray-500 opacity-90`;

export default class ManageSubscription extends Component {

  constructor(props) {
    super(props);

    this.state = {
      customerEmail: '',
      invoiceNumber: '',
      errorMessage: '',
    }

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {

    this.setState({
      'errorMessage': '',
    });

  }

  async handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    // wait for setstate to complete
    await this.setState({
      [name]: value
    });
    // now proceed to validate
    let canLookup = false;
    if( this.state.invoiceNumber &&
      /(.+)@(.+){2,}\.(.+){2,}/.test( this.state.customerEmail ) ) {
      canLookup = true;
    }
    // this is used to enable/disable checkout button
    this.setState({
      'canLookup': canLookup
    });
  }

  lookupPortal( event )
  {
    event.preventDefault();
    if(this.state.loading) {
      return
    }
    this.setState({'loading': true});

    fetch( lookupUrl , {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        customerEmail: this.state.customerEmail,
        invoiceNumber: this.state.invoiceNumber
      })
    })
    .then(response => {
      this.setState({'loading': false});
      return response.json();
    })
    .then(data => {
      if ( data.url ) {
        window.location.href = data.url;
      } else if ( data.message ) {
        this.setState({'errorMessage': data.message});
      }
    })
    .catch(error => {
      this.setState({'errorMessage': error.message});
    });

  }

  render()
  {

    return (
      <section className="container">
        <h2 className="mb-4 text-3xl font-bold text-center tracking-wide">Manage Subscription</h2>
        { this.state.loadingStripe && <Spinner className="block w-5 mx-auto" />}
        <div className="flex flex-wrap mb-8 px-4 md:px-0 sm:-mx-2 md:-mx-4">
          <div className="w-full mb-4 mx-auto sm:w-1/2 md:w-1/3 p-4 bg-white">
            <p className="mb-8">Please enter your email and FasTrack subscription invoice number.</p>
            <form className="flex flex-col" action="post" onSubmit={(e) => this.lookupPortal(e)}>
              <label htmlFor="customerEmail"
                className="mb-2 text-sm text-gray-900">Email Address</label>
              <input type="email"
                id="customerEmail"
                name="customerEmail"
                required
                className={inputClasses}
                value={this.state.customerEmail}
                onChange={e => this.handleChange(e)} />
              <label htmlFor="invoiceNumber"
                className="mb-2 text-sm text-gray-900">Invoice Number</label>
              <input type="text"
                id="invoiceNumber"
                name="invoiceNumber"
                required
                className={inputClasses}
                value={this.state.invoiceNumber}
                onChange={e => this.handleChange(e)} />
              <div className="text-center">
                <button className="btn btn-light"
                  disabled={!this.state.canLookup}
                  onClick={(e) => this.lookupPortal(e)}>Manage Subscriptions</button>
                {this.state.loading && <Spinner className="ml-2" />}
                {this.state.errorMessage && 
                  <p className="mt-4 py-2 text-sm bg-red-100 text-red-700">{this.state.errorMessage}</p>
                }
              </div>
            </form>
          </div>
        </div>
      </section>
    )
  }

}